// Auto Search 
.react-autosuggest__input {
  width: 100%;
  height: 35px;
  border-radius: 4px;
  padding: 0.375rem 0.75rem;
  border: 1px solid #8394A7;

  &.react-autosuggest__input--focused {
    border-color: $primary  !important;
    box-shadow: 0 0 0 0.2rem rgba(0, 221, 149, 0.25);
    outline: 0;
  }
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style: none;
  padding-left: 0.75rem;
  padding-top: 10px;
  padding-bottom: 10px;
}


// search-wrapper
.search-wrapper {
  position: relative;
  width: 100%;

  .search-result {
    position: absolute;
    top: 40px;
    left: 0;
    background: $white;
    // background: red;
    width: 100%;
    z-index: 10;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 5px;
    max-height: 300px;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s ease-in-out;

    li {
      cursor: pointer;
      padding: 10px 14px;

      &:hover {
        background-color: rgba($info, .1);
        border-radius: 5px;
      }
    }

    &.active {
      visibility: visible;
      opacity: 1;
      z-index: 10;
      border: 1px solid $border-color;

    }
  }

  .close {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
  }

  .selected-valuewraaper {
    height: calc(1.5em + 0.75rem + 2px);
    border: 1px solid #8394A7;
    padding-left: 15px;
    padding-right: 15px;
  }


}