// Media 
.avatar {
  width: 40px;
  height: 40px;
}

// Media Small 
.media-sm {
  .media-object {
    width: 36px;
    height: 36px;
    border-radius: 50%;
  }
}

// Media Icon 
.media-icon {
  padding: 20px;
  border-bottom: 1px solid $border-color;

  // title 
  .title {
    margin-bottom: 0;
    color: $dark;
  }

  // Single Item 
  .single-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 3px;
    padding-bottom: 3px;
  }

  &:first-child {
    padding-top: 10px;
  }
}